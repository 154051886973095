<template>
  <VAlert
    :value="isOpen"
    color="bluegiant-blue"
    class="global-notice"
    transition="fade-slow"
    mode="in-out"
  >
    <VLayout row justify-center>
      <VFlex xs10 sm8 lg6>
        <BaseSiteContent
          tag="h2"
          content-key="covid_alert_title"
          default-content="An Update from Blue Giant"
        />
        <BaseSiteContent
          tag="p"
          content-key="covid_alert_text"
          default-content="In order to be able to continue to serve food in the most responsible way possible we will only be open for carry out for the foreseeable future. To place an order, please call the restaurant and come collect it when it is ready.

          Thank you for your understanding and support, and we look forward to serving you."
        />
        <BaseButton color="white" class="ml-0" outline @click="buttonClick"
          >Ok, Show Me The Menu</BaseButton
        >
      </VFlex>
    </VLayout>
  </VAlert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BlueGiantGlobalNotice',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalNoticeState']),
    isOpen: {
      get() {
        return this.getGlobalNoticeState
      },
      set() {
        this.toggleGlobalNotice()
      },
    }, // isOpen
  }, // computed
  methods: {
    ...mapActions('nav', ['toggleGlobalNotice']),
    buttonClick() {
      this.toggleGlobalNotice()
      if (this.$route.path !== '/locations/blue-giant') {
        this.$router.push({ path: '/locations/blue-giant' })
      }
    }, // buttonClick
  },
}
</script>

<style lang="css" scoped>
.global-notice {
  height: auto;
  min-height: 60vh;
  font-size: 20px;
  margin: auto;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
</style>
